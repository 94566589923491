
const tempApis = {
    "": {
        registerApi:'/app/register',
        loginApi:'/app/login',
        czInfoApi:'/app/business/cz/info',       
        accountApi:'/app/business/my/account',    
        czAddApi:'/app/business/cz/add',
        czListApi:'/app/business/cz/list',
        txInfoApi:'/app/business/tx/info',    
        txAddApi:'/app/business/tx/add',
        txListApi:'/app/business/tx/list',

        resetTransactionPwdApi:"/app/business/resetTransactionPwd",
        appConfigApi:"/app/business/config",
        resetLoginPwdApi:"/app/business/resetLoginPwd",
        payApi:"/app/business/my/order/pay",
        cancelPayApi:"/app/business/my/order/cancelPay",
        graborderApi:"/app/business/grab/task",
        orderListApi:"/app/business/my/order/list",
        orderDetailApi:"/app/business/task/info",
        lbtApi:'/app/business/lbt/list',
        taskListApi:'/app/business/user/task/list',
        indexTaskListApi:'/app/business/task/newest'



        
        
    },

    
};

let apis = {};
Object.keys(tempApis).forEach((item) => {
    Object.keys(tempApis[item]).forEach((key) => {
        apis[key] = item + tempApis[item][key];
    });
});
export default apis;
