import {mapGetters} from "vuex";


export default {
    data(){
        return {
            langOptions:[],
            showlangAction:false
        }
    },
    mounted(){
        console.log('stateMixin')

        let token = sessionStorage.getItem('token')
        

    },
    computed:{
        ...mapGetters(['accountInfo','userInfo','urlPrefix']),
    },
    methods: {
     
        _logout(done){
            sessionStorage.removeItem('token')
            this.$store.commit("home/setToken", '');
            this.$router.replace('/login')
            done && done()
        },
        changeLang(){
            this.initLang()
            this.showlangAction = true
        },
        initLang() {
            this.langOptions = [
              {
                locale: "en",
                name: this.$t("lang.en"),
              },
              {
                locale: "zh",
                name: this.$t("lang.zh"),
              },
              {
                locale: "tr",
                name: this.$t("lang.trTR"),
              },
            ];
          },
          onSelect(item) {
            let locale = this.$i18n.locale;
      
            if (item.locale != locale) {
              localStorage.setItem('tLocales',item.locale)
      
              this.$i18n.locale = item.locale;
              this.showlangAction = false
              this.initLang();
            }
          },

      
    },
}