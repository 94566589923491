export default {
    app: {
        hello: 'Merhaba,',
        username:'Kullanıcı Adı',
        phonenumber:'Telefon Numarası',
        submit:'Gönder',
        changePwd:'Şifre Değiştir',
        changePayPwd:'Change Pay Password',
        logoutTitle:'Tamam mı',
        cz:'Şarj',
        tx:'Para Çekme',
        qxdd:'Siparişlerin iptali',
        logoutHint:'Hesabı kapatmak istediğinizden emin misiniz'
    },
    tab:{
        home:'Sayfa',
        act:'Sipariş',
        order:'Siparişlerim',
        user:'Benim',
        customer:'İLK YARDIM'
    },

    user:{
        logout:'Çıkış',
        login:'Giriş Yap',
        register:'Kayıt Ol',
        paypassword:'İşlem Şifresi',
        password:'Şifre mi',
        inviteCode:'inviteCode',
        banlance:'Bakiye',
        todayReward:'YüklemeTutarı',
        totalReward:'Tüm Ödüller',
        recharge:'Yükleme',
        copy:'Kopyala',
        copySuccess:'Başarıyı Kopyala',
        loginsuccess:'İçeri Başarıları'

    },
    account:{
        recharge:'Yükleme',
        withdrawal:'Çekim',
        invite:'Invite Friend',

        inviteCode:'Davet Kodu',
        copy:'Kopyala',

        djAmount:'Bekletilen',
        czAmount:'Yükleme',
        zhAmount:'Bakiye',

        ststusToConfirm:'Bekliyor',
        txTimeZone:'Günlük ',
        czTimeZone:'Daily recharge time period',
        confirmTx:'Confirm withdrawal',
        confirmCz:'Confirm recharge',
    },
    lang:{
        en:'English',
        zh:'Chinese',
        trTR:'Turkish'
    },
    base:{
        awards:'Ödüller',
        tasks:'Görev',
        list:'List',
        grab:'Sıra',
        brokerage:'Komisyon',
        noData:'Kayıt Yok',
        hotel:'Hotel',
        xiadan:'Sıra',
        rank:'Tavsiye Listesi',
        update:'Update',
        edit:'Edit',
        inputHint:'Giriniz ',
        chooseHint:'Giriniz ',
        newPassword:'Yeni',
        comfirmPassword:'Onayla',
        newAndConfirmHit:'The new password and the confirmed password do not match',
        success:"success",
        fail:'fail',
        setLang:'Dil Ayarı',
        czAmount:'Yükleme',
        txAmount:'Çekim',
        accountInfo:'Hesap',
        noMore:'Daha Fazla Yok',
        toPay:'Bekleyen',
        paySuccess:'Ödeme Başarılı',
        payFail:'Başarısız',
        payReady:'Ödendi',
        done:'Başarılı',
        donehint:'Completed Task, obtain',
        detail:'Detayları',
        amount:'Amount',
        time:'time',
        addr:'Adresi',
        loading:'Yükleniyor',
        updatepassword:'Şifre Değiştir',
        updatePayPassword:'Transaksyon Parola Değiştir',
        zdyamount:'Özel miktar',
    },
    order:{
        orderNo:'Sipariş No',
        orderTime:'Sipariş Zamanı',
        payTime:'payTime',
        taskAmount:'Görev Tutarı',
        yjAmount:'Komisyon',
        lastpayTime:'Son Ödeme Zamanı',
        toPay:'Bekleyen',
        cancelPay:'Ödemelerin iptali',
        done:'Ödendi',
        donehint:'Completed Task, obtain',
        yesYjAmount:'Uzlaşılan ödüller',
        noYjAmount:'Uzlaşılmamış ödüller',
        grabOrderSuccess:'Siparişi başarıyla alın'

    },
    login:{
        phone:'Telefon Numarası',
        password:'Şifre',
        paypassword:'İşlem Şifresi',
        inviteCode:'Davet Kodu',
        register:'Kayıt Ol',
        toLogin:'Giriş Yap',
        login:'Giriş Yap',
        username:'Kullanıcı Adı',
        userHint:"Lütfen Kullanıcı Adınızı Girin",
        phoneHint:'Lütfen Telefon Numaranızı Girin',
        passwordHint:'Lütfen Şifrenizi Girin',
        payWordHint:'Lütfen İşlem Şifrenizi Girin'
 
    }
}