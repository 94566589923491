<template>
  <div id="app">
    <!-- <div class="btn-switch-btn" @click="switchAccount"></div>
    <div class="btn-switch-btn-right" @click="toggleVc"></div> -->

      <!-- <keep-alive>
        <transition :name="transitionName">
        <router-view v-if="$route.meta.keepAlive" />
        </transition>
      </keep-alive>
      <transition :name="transitionName">
        <router-view v-if="!$route.meta.keepAlive" />
      </transition> -->
      <transition :name="transitionName">
        <keep-alive :max="10">
            <router-view v-if="$route.meta.keepAlive"></router-view>
            <router-view v-if="!$route.meta.keepAlive" :key="new Date().getTime()"></router-view>
        </keep-alive>
    </transition>
    <!-- <transition :name="transitionName">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view class="router-view"
      :key="$route.name" ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      class="router-view"
      :key="$route.name"
    ></router-view>
  </transition> -->

    <BottomTabbar v-if="isTabbarShow"></BottomTabbar>

  </div>
</template>
<script>
import BottomTabbar from '@/views/index/components/BottomTabbar.vue'
import VconsoleMixin from '@/pages/mixins/vconsole.js'
export default {
  name: "App",
  data(){
    return {
      transitionName: 'slide-left'
    }
  },
  components: {
    BottomTabbar
  },
  mixins:[VconsoleMixin],
  computed:{
    isTabbarShow(){
      return this.$route.path !='/' && this.$route.path!='/register'
      && this.$route.path!='/login'

    }
  },
  methods: {
  },
  watch: {
      $route(to, from) {
        // 有主级到次级
        if (to.meta.index > from.meta.index) {
          this.transitionName = 'slide-left' // 向左滑动
          // || !to.meta.index
        } else if (to.meta.index < from.meta.index ) {
          // 由次级到主级
          this.transitionName = 'slide-right'
        } else {
          this.transitionName = ''   //同级无过渡效果
        }
      }
    }
};
</script>
<style lang="less" scoped>
@import "~@/styles/common.less";
.btn-switch-btn {
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 2;
  width: 200px;
  height: 40px;
}
.btn-switch-btn-right {
  position: absolute;
  right: 0px;
  top: 10px;
  z-index: 2;
  width: 200px;
  height: 40px;
}
</style>

<style lang="less">
.copy-row{
  font-size: 16px;
  line-height: 24px;
}
.copy-text{
  color: #af4d4e;
  flex:none;
  font-size: 14px;
}
.mt10{
  margin-top: 10px;
}
.base-flex{
  display:flex;
  &.align-center{
    align-items: center;
  }
  &.sb{
    justify-content: space-between;
  }
  &.jccenter{
    justify-content: center;
  }
  &.column{
    flex-direction: column;
  }
  &.none{
    flex:none
  }
  // &.flex1{
  //   flex:1
  // }
}
.fixed-head{
  position: fixed;
  left:0px;
  right:0px;
  top:0px
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active{
    height: 100%;
    will-change: transform;
    transition: all 500ms;
    position: absolute;
    backface-visibility: hidden;
}
.slide-right-enter{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-enter{
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active{
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
</style>
