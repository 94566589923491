
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
const pageRouters = [
    {
        path: "/sso",
        name: "sso",
        meta:{
            requireAuth:false
        },
        component: () => import("@/pages/sso.vue"),
    },
    {
        path: "/login",
        name: "login",
        meta:{
            requireAuth:false
        },
        component: () => import("@/pages/login.vue"),

    },
    {
        path: "/register",
        name: "register",
        meta:{
            requireAuth:false
        },
        component: () => import("@/pages/register.vue"),

    },
   
]
const router = new Router({
    routes: [
        ...pageRouters,
        {
            path: "/",
            name: "首页",
            redirect: (to) => {
                return { path: '/index' };
            },
        },
        
        
        {
            path: "/index",
            name: "首页",
            component: () => import("@/views/index/index.vue"),
            meta: {
                requireAuth: true,
            }
        },
        {
            path: "/order",
            name: "订单",
            component: () => import("@/views/order/index.vue"),
            meta: {
                requireAuth: true,
            }
        },
        {
            path: "/orderDetail",
            name: "orderDetail",
            component: () => import("@/views/order/orderDetail.vue"),
            meta: {
                requireAuth: true,
                inedx:1
            }
        },
        {
            path: "/activity",
            name: "抢单",
            component: () => import("@/views/activity/index.vue"),
            meta: {
                requireAuth: true,
            }
        },
        {
            path: "/activityDetail",
            name: "抢单详情",
            component: () => import("@/views/activity/activityDetail.vue"),
            meta: {
                requireAuth: true,
            }
        },
        {
          path: "/customer",
          name: "客服",
          component: () => import("@/views/customer/index.vue"),
          meta: {
              requireAuth: true,
          },
         
      },
        {
            path: "/user",
            name: "我的",
            component: () => import("@/views/user/index.vue"),
            meta: {
                requireAuth: true,
            },
           
        },
        {
            path: "/changePwd",
            name: "修改密码",
            component: () => import("@/views/user/changePwd.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        },
        {
            path: "/changePayPwd",
            name: "修改交易密码",
            component: () => import("@/views/user/changePayPwd.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        },
        {
            path: "/accountInfo",
            name: "accountInfo",
            component: () => import("@/views/user/account/info.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        },
        {
            path: "/recharge",
            name: "充值",
            component: () => import("@/views/user/account/recharge.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        },

        {
            path: "/withdraw",
            name: "充值",
            component: () => import("@/views/user/account/withdraw.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        },
        {
            path: "/toRecharge",
            name: "去充值",
            component: () => import("@/views/user/account/toRecharge.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        }, {
            path: "/toWithdraw",
            name: "去提现",
            component: () => import("@/views/user/account/toWithdraw.vue"),
            meta: {
                requireAuth: true,
                index:1
            },
        }
        
     
    ],
});
router.beforeEach((to, from, next) => {
    let token = sessionStorage.getItem('token')
    let isSSO = process.env.VUE_APP_IS_SSO == 'true'
    let isNeedLogin = process.env.VUE_APP_IS_NEED_LOGIN == 'true'

    if(isNeedLogin){
        if (isSSO) {
            // 判断打开的router是否需要验证登录
            if (to.meta.requireAuth) {
                if (token && token != '') {
                    // 正常跳转
                    next()
                } else {
                    // 转回登录界面
                    next({ name: 'sso', query: { redirect: to.fullPath },params:to.query})
                }
            } else if (token && token != '') {
                next()
            } else {
                // 正常跳转
                next()
            }
        }else{
            // 判断打开的router是否需要验证登录
            if (to.meta.requireAuth) {
                // 判断是否已登录
                if (token && token != '') {
                    next()
                } else {
                    next({ name: 'login',query: { redirect: to.fullPath } })
                }
            } else if (token && token != '') {
                next({ path: '/' })
            } else {
                // 正常跳转
                next()
            }
        }
    }else{
        next()
    }

})


export default router;
