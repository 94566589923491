export default {
    data() {
        return {
        }
    },
    mounted(){
       
    },
    methods: {
        hasClass(obj, cls) {
            return obj.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
        },
        addClass(obj, cls) {
            if (!this.hasClass(obj, cls)) obj.className += " " + cls;
        },
        toggleClass(obj, cls) {
            if (this.hasClass(obj, cls)) {
                this.removeClass(obj, cls);
            } else {
                this.addClass(obj, cls);
            }
        },
        removeClass(obj, cls) {
            if (this.hasClass(obj, cls)) {
                var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
                obj.className = obj.className.replace(reg, " ");
            }
        },
        // 显示调试
        toggleVc() {
            const nowTime = new Date().getTime();
            if (nowTime - this.lastClickTimeV < 3000) {
                this.countV++;
            } else {
                this.countV = 0;
            }
            this.lastClickTimeV = nowTime;
            if (this.countV >= 10) {
                let vconDom = document.getElementById("__vconsole");
                this.toggleClass(vconDom, "show");
                this.countV = 0;
            }
        },
        // 跳转登录页面
        switchAccount() {
            const nowTime = new Date().getTime();
            if (nowTime - this.lastClickTime < 3000) {
                this.count++;
            } else {
                this.count = 0;
            }
            this.lastClickTime = nowTime;
            if (this.count >= 10) {
                this.$router.replace({
                    name: "login",
                    query: { redirect: this.$route.fullPath },
                });
                this.count = 0;
            }
        },

    }
}
