<template>
  <div class="bottom-tabbar">
    <van-tabbar v-model="active" >
      <!-- replace to="/personCheck" -->
      <van-tabbar-item @click="replaceRouter('/index')">
        {{ $t('tab.home') }}
        <template v-slot:icon="props">
          <img
            v-if="props.active"
            class="scan-info-icon nfc"
            src="@/assets/index/tabbar/tab-home-active.png"
          />
          <img
            v-else
            class="scan-info-icon nfc"
            src="@/assets/index/tabbar/tab-home.png"
          />
        </template>
      </van-tabbar-item>
      <!-- replace to="/carCheck" -->
      <van-tabbar-item @click="replaceRouter('/order')">
        {{ $t('tab.order') }}

        <template v-slot:icon="props">
          <img
            v-if="props.active"
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-order-active.png"
          />
          <img
            v-else
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-order.png"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="replaceRouter('/activity')">
        {{ $t('tab.act') }}

        <template v-slot:icon="props">
          <img
            v-if="props.active"
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-act-active.png"
          />
          <img
            v-else
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-act.png"
          />
        </template>
      </van-tabbar-item>
       <van-tabbar-item @click="replaceRouter('/customer')">
        {{ $t('tab.customer') }}
        <template v-slot:icon="props">
          <img
            v-if="props.active"
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-customer-active.png"
          />
          <img
            v-else
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-customer.png"
          />
        </template>
      </van-tabbar-item>
      <!-- replace to="/personStatistics" -->
      <van-tabbar-item @click="replaceRouter('/user')">
        {{ $t('tab.user') }}

        <template v-slot:icon="props">
          <img
            v-if="props.active"
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-people-active.png"
          />
          <img
            v-else
            class="scan-info-icon ocr"
            src="@/assets/index/tabbar/tab-people.png"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem, Toast } from "vant";
import stateMixin from "@/mixins/stateMixin";

export default {
  name: "bottomTabbar",
  mixins: [stateMixin],
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
  },
  data() {
    return {
      // active:0
    };
  },
  computed: {
    active: {
      get() {
        switch (this.$route.path) {
          case "/index":
            return 0;
          case "/order":
            return 1;
          case "/activity":
            return 2;
          case "/customer":
            return 3;
          case "/user":
            return 4;
          default:
            return -1;
        }
      },
      set() {},
    },
  },
  mounted() {
    console.log(this.$route.path);
  },
  methods: {
    replaceRouter(path) {
     
      if(this.$route.path != path){
            this.$router.replace({ path });
      }
     
    },

  },
};
</script>

<style lang="less" scoped>
.bottom-tabbar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  ::v-deep .van-tabbar {
    height: 66px;
  }

  ::v-deep .van-tabbar-item__text {
    color: #898989;
    font-size: 13px;
    line-height: 1.4;
  }
  ::v-deep .van-tabbar-item--active {
    .van-tabbar-item__text {
      color: #af4d4e;
      line-height: 1.4;
    }
  }
}
</style>
