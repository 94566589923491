import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'
import trTR from 'vant/lib/locale/lang/tr-TR'

import enLocale from './en_us'
import zhLocale from './zh_cn'
import trLocale from './tr-TR'

Vue.use(VueI18n)

const messages = {
  en: {
    ...enUS,
    ...enLocale
  },
  zh: {
    ...zhCN,
    ...zhLocale
  },
  tr: {
    ...trTR,
    ...trLocale
  }
}
const savedLanguage = localStorage.getItem('tLocales');
const language = savedLanguage || 'tr'; // 默认为英语


const i18n = new VueI18n({
  locale: language,  // 设置默认语言
  messages: messages // 设置资源文件对象
})

// 更新vant组件库本身的语言变化，支持国际化
function vantLocales(lang) {
  if (lang === 'en') {
    Locale.use(lang, enUS)
  } else if (lang === 'zh') {
    Locale.use(lang, zhCN)
  } else if (lang === 'tr') {
    Locale.use(lang, trTR)
  }
}

export { i18n, vantLocales }