import axios from "@/libs/axios";
import api from "./api";
const scope = 'server'

let config = {
    headers: { 'Content-Type': 'application/json' }
};

let urlConfig = {
    headers: { "Content-Type": "application/x-www-form-urlencoded" }
   
};
export function registerApi(data){
    return axios.post(api.registerApi, data, config)

}
export function loginApi(data){
    return axios.post(api.loginApi, data, config)

}

export function accountApi(data) {
    return axios.get(api.accountApi, {params:data}, config)
}

export function resetTransactionPwdApi(data) {
    return axios.post(api.resetTransactionPwdApi, data, config)
}
export function appConfigApi(data) {
    return axios.get(api.appConfigApi, {params:data}, config)
}
export function resetLoginPwdApi(data) {
    return axios.post(api.resetLoginPwdApi, data, config)
}
