const baseSize = 75 // 这个是跟postcss.config.js中rootValue的值是一致的
// 设置 rem 函数
function setRem () {
	let docEl = document.documentElement
	// let width = process.env.VUE_APP_EQUIPMENT_FLAG == "pc" ? 1580 : 375
	let width = 375
	// 当前页面宽度相对于 375 宽的缩放比例，可根据自己需要修改。  vantUI使用的人375px页面宽，这里使用375px
	const scale = docEl.clientWidth / width
	// 设置页面根节点字体大小 最高为两倍图 即设计稿为750
	let fontsize = baseSize * Math.min(scale, 2)
	docEl.style.fontSize = fontsize + 'px'
	let real_fontsize = ~~(window.getComputedStyle(docEl).fontSize.replace('px',''))
	// console.log(rem,real_rem)
	// if(process.env.NODE)
	if(fontsize !== real_fontsize) {
		docEl.style.fontSize = fontsize*(fontsize/real_fontsize) + 'px';
	}

	// console.log(fontsize,real_fontsize)
	// document.documentElement.style.fontSize = fontSize + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem()
}
