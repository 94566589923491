export default {
    app: {
        hello: 'Hello,',
        username:'User Name',
        phonenumber:'Phone Number',
        submit:'Submit',
        changePwd:'Change Password',
        changePayPwd:'Change Pay Password',
        logoutTitle:'confirm',
        cz:'Recharge',
        tx:'Withdrawal',
        qxdd:'Cancel Order',
        logoutHint:'Are you sure to log out of the account'
    },
    tab:{
        home:'Home',
        act:'Orders',
        order:'My Orders',
        user:'My',
        customer:'Customer'
    },

    user:{
        logout:'Logout',
        login:'Login',
        register:'Register',
        paypassword:'paypassword',
        password:'password',
        inviteCode:'Invitation Code',
        banlance:'Balance',
        todayReward:'RechargeAmount',
        totalReward:'totalReward',
        recharge:'Recharge',
        copy:'Copy',
        copySuccess:'Copy Successs',
        loginsuccess:'Login Successs'

        
    },
    account:{
        recharge:'Deposit',
        withdrawal:'Withdrawal',
        invite:'Invite Friend',

        inviteCode:'inviteCode',
        copy:'Copy',

        djAmount:'Frozen Amount ',
        czAmount:'Recharge Amount',
        zhAmount:'Account amount',

        ststusToConfirm:'Pending',
        txTimeZone:'Daily withdrawal time period',
        czTimeZone:'Daily recharge time period',
        confirmTx:'Confirm withdrawal',
        confirmCz:'Confirm recharge',
    },
    lang:{
        en:'English',
        zh:'Chinese',
        trTR:'Turkish'
    },
    base:{
        awards:'Awards',
        tasks:'Tasks',
        list:'List',
        grab:'Order',
        brokerage:'Brokerage',
        noData:'No Records Available',
        hotel:'Hotel',
        xiadan:'Order',
        rank:'Ranks',
        update:'Update',
        edit:'Edit',
        inputHint:'Enter ',
        chooseHint:'Enter ',
        newPassword:'New',
        comfirmPassword:'Confirm',
        newAndConfirmHit:'The new password and the confirmed password do not match',
        success:"success",
        fail:'fail',
        setLang:'Language',
        czAmount:'Recharge Amount',
        txAmount:'Withdrawal Amount',
        accountInfo:'AccountInfo',
        noMore:'No More',
        toPay:'Pending Payment',
        paySuccess:'Successful',
        payFail:'Failed',
        payReady:'Paid',
        done:'Done',
        donehint:'Completed Task, obtain',
        detail:'Details',
        amount:'Amount',
        time:'time',
        addr:'Address',
        loading:'Loading',
        updatepassword:'Change Password',
        updatePayPassword:'Change Transaction Password',
        zdyamount:'Custom Amount',

    },
    order:{
        orderNo:'Order No',
        orderTime:'Order Time',
        payTime:'Pay Time',
        taskAmount:'Amount',
        yjAmount:'Commission',
        lastpayTime:'Last Payment Time',
        toPay:'Pending',
        cancelPay:'Cancel Pay',
        done:'Paid',
        donehint:'Completed Task, obtain',
        yesYjAmount:'Settled awards',
        noYjAmount:'Unsettled awards',
        grabOrderSuccess:'order successfully'
    },
    login:{
        phone:'Phone Number',
        password:'Password',
        paypassword:'Transaction Password',
        inviteCode:'Invitation Code',
        register:'Register',
        toLogin:'Go to Login',
        login:'Login',
        username:'Username',
        userHint:"Please Enter Username",
        phoneHint:'Please Enter Phone Number',
        passwordHint:'Please Enter Password',
        payWordHint:'Please Enter Transaction Password'
 
    }
}