
export default {
    app: {
        hello: 'Merhaba，',
        username:'用户名',
        phonenumber:'手机号',
        submit:'提交',
        changePwd:'修改密码',
        changePayPwd:'修改交易密码',
         logoutTitle:'确认',
         cz:'充值',
         tx:'提现',
         qxdd:'取消订单',
        logoutHint:'是否确认退出该账号'
    },
    tab:{
        home:'首页',
        act:'抢单',
        order:'我的订单',
        user:'我',
        customer:'客服'
    },
    user:{
        logout:'退出',
        login:'登录',
        register:'注册',
        paypassword:'交易密码',
        password:'密码',
        inviteCode:'邀请码',
        banlance:'账户余额',
        todayReward:'充值金额',
        totalReward:'全部奖励',
        recharge:'充值',
        copy:'复制',
        loginsuccess:'登录成功'

        
    },
    account:{
        recharge:'充值',
        withdrawal:'提现',
        invite:'邀请朋友',
        inviteCode:'邀请码',
        copy:'复制',

        djAmount:'冻结金额',
        czAmount:'充值金额',
        zhAmount:'账户金额',
        ststusToConfirm:'待核实',
        txTimeZone:'每日提现时间段',
        czTimeZone:'每日充值时间段',
        confirmTx:'确认提现',
        confirmCz:'确认充值',
        copySuccess:'复制成功',

    },
    lang:{
        en:'英语',
        zh:'中文',
        trTR:'土耳其语'
    },
    base:{
        awards:'奖励明细',
        tasks:'任务',
        list:'列表',
        grab:'抢单',
        brokerage:'佣金',
        noData:'暂无记录',
        hotel:'酒店',
        xiadan:'下单',
        rank:'推荐榜',
        update:'修改',
        edit:'编辑',
        inputHint:'请输入',
        chooseHint:'请选择',
        newPassword:'新密码',
        comfirmPassword:'确认密码',
        newAndConfirmHit:'新密码和确认密码不一致',
        success:"成功",
        fail:'失败',
        setLang:'设置语言',
        czAmount:'充值余额',
        txAmount:'提现余额',
        accountInfo:'账户详情',
        noMore:'没有更多了',
        toPay:'待支付',
        paySuccess:'支付成功',
        payFail:'支付失败',
        payReady:'已支付',
        done:'完成',
        donehint:'任务,获得佣金',
        detail:'详情',
        amount:'金额',
        time:'时间',
        addr:'地址',
        loading:'加载中',
        updatepassword:'修改密码',
        updatePayPassword:'修改交易密码',
        zdyamount:'自定义金额',

    },
    order:{
        orderNo:'订单编号',
        orderTime:'下单时间',
        payTime:'付款时间',
        taskAmount:'任务金额',
        yjAmount:'佣金金额',
        lastpayTime:'最后付款时间',
        toPay:'去支付',
        cancelPay:'取消支付',
        done:'完成',
        donehint:'任务,获得佣金',
        yesYjAmount:'已结算佣金',
        noYjAmount:'未结算佣金',
        grabOrderSuccess:'抢单成功'

    },
    login:{
        phone:'手机号码',
        password:'密码',
        paypassword:'交易密码',
        inviteCode:'邀请码',
        register:'注册',
        toLogin:'去登录',
        login:'登录',
        username:'用户名',
        userHint:"请填写用户名",
        phoneHint:'请填写手机号码',
        passwordHint:'请填写密码',
        payWordHint:'请填写交易密码'
 
    }
    

}