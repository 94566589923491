import {accountApi,appConfigApi} from '@/service/baseInfo.js'
export default {
    namespaced: true,
    state:{
        userInfo:null,
        token:'',
        accountInfo:null,
        urlPrefix:''
    },
    getters: {   //相当于vue中的计算属性computed
        getToken: () => {
            return state.token
        },
        getUrlPrefix:()=>{
            return state.urlPrefix
        }
    
    },
    mutations:{
        setUserInfo(state,value){
            state.userInfo = value
        },
         
        setToken(state,value){
            state.token = value
        },
    
        setAccountInfo(state,value){
            state.accountInfo = value
        },
        setUrlPrefix(state,value){
            state.urlPrefix = value
        }
    },
    actions:{
        // 刷新token

        GET_ACCOUNT({ commit, state},context){
        return new Promise((resolve, reject) => {
         
    
            accountApi().then(response => {
                const data = response.data
                let userInfo  = data.tUser

                sessionStorage.setItem('accountInfo',JSON.stringify(data))
                sessionStorage.setItem('userInfo',JSON.stringify(userInfo))

                commit('setUserInfo',userInfo)
                commit('setAccountInfo', data)

                resolve()
            }).catch(error => {
                reject(error)
            })
            })
        },

        GET_CONFIG({ commit, state},context){
            return new Promise((resolve, reject) => {
            
                appConfigApi().then(response => {
                    const url_prefix = response.data.url_prefix
                    sessionStorage.setItem('urlPrefix',url_prefix)
                    commit('setUrlPrefix',url_prefix)
    
                    resolve()
                }).catch(error => {
                    reject(error)
                })
                })
            },
    }

}
