/* eslint-disable eqeqeq */
/* eslint-disable no-tabs */
import axios from 'axios' // 引入 axios库
import qs from 'qs' // 引入 node中自带的qs模块（数据格式转换）
import Vue from 'vue'
import { Toast } from 'vant';
import { getQueryVariable } from "@/libs/utils.js";
import store from '@/store';
let isDev = true
const axiosA = axios.create({
    timeout: 5 * 1000 * 60,
    baseURL: process.env.VUE_APP_BASE_URL, //地址1

})
/* 3.设置拦截器 */
/* 如果不是用创建实例的方式配置，那么下面的Axios都要换成axios,也就是文件开头你用import引入axios
时定义的变量 */
axiosA.interceptors.request.use((config) => {
    if (!config.noLoading) {
        Vue.$loading.show(config.url, Vue.$t && Vue.$t('base.loading'))
    }
    const isToken = (config.headers || {}).isToken === false
    const isSecondToken = config.isSecondToken
    if (isSecondToken) {
        let token = config.token || sessionStorage.getItem('acc_token')
        if (token) {
            config.headers['Authorization'] = token// token
        }
    } else {
        let token = config.token || sessionStorage.getItem('token')
        if (token && !isToken) {
            config.headers['Authorization'] = 'Bearer ' + token// token
        }
    }
    if (isDev) {
        console.info('%c 请求接口::' + '%c ' + config.url, 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');
        console.info('%c 请求数据::' + '%c ', 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');

        console.info(config.data ? JSON.parse(JSON.stringify(config.data)) : '无');
    }
    /// 需要上传文件类型的，config.type === 'formData' 作为过滤判断
    if (config.headers['Content-Type'] === 'application/json') {
        return config;
    }
    if (isDev) {
        console.info('%c 请求接口::' + '%c ' + config.url, 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');
        console.info('%c 请求数据::' + '%c ', 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');

        console.info(config.data ? JSON.parse(JSON.stringify(config.data)) : '无');
    }
    if (config.type !== 'formData' && config.method === 'post') { // post 请求默认转成 application/x-www-form-urlencoded 方式
        config.data = qs.stringify(config.data);
        if (isDev) {
            console.info('%c 请求接口::' + '%c ' + config.url, 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');
            console.info('%c 请求数据::' + '%c ', 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');
            console.info(config.data ? config.data : '无');
        }
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        // config.data = qs.stringify(config.data);
    }

    if (config.method === 'get') {
        config.url = serialize(config)
    }

    return config;

    //  	if (config.method == "post") {
    //   /*数据转换: axios post方式默认是json格式提交数据，如果使用application/x-www-form-urlencoded数据格式提交，要用qs.stringify()进行转换,个人建议不在拦截器中全局配置，因为不够灵活，还有一点是，如果
    // 设置了重新请求的配置，那么重新请求时，请求体中的config里面的传参就会被再次进行qs.stringify()转
    // 换，会使得参数丢失，造成请求失败。*/
    //  		config.data = qs.stringify(config.data)
    //  	}
    // 	return config;
}, (error) => {
    Vue.$loading.hide()
    return Promise.reject(error)
})
axiosA.interceptors.response.use((response) => {
    Vue.$loading.hide()
    let res = response.data;
    if (isDev) {
        // console.info('%c请求接口::' + '%c ' + response.config.url, 'color:#fff;font-weight:bold;font-size:14px;background-color:#ec7c07;', 'color:#ec7c07;font-size:14px;font-weight:bold;');
        console.info('%c '+ response.config.url + '返回数据::' + '%c ', 'color:#fff;font-weight:bold;font-size:14px;background-color:#3366ff;', 'color:#ec7c07;font-size:14px;font-weight:bold;');
        console.info(res);

    }
    // 请求响应后拦截
    switch (response.status) {

        case 200:
            if (response.config.url.indexOf('/common/file/download') >= 0 ||
                response.config.url.indexOf('/daily/info/export/excel') >= 0 ||
                response.config.url.indexOf('/auth/oauth2/token') >= 0) {

                return Promise.resolve(res);
            } else {
                if (res.code != 0 && res.code != 200 && res.status != 200) {
                    if(res.code == 401){
                        sessionStorage.removeItem('token')
                        window.location.reload()
                        return Promise.reject(new Error(res.msg ? res.msg : '数据返回错误'))

                    }else{
                        return Promise.reject(new Error(res.msg ? res.msg : '数据返回错误'))

                    }
                    return Promise.reject(new Error(res.msg ? res.msg : '数据返回错误'))
                } else{
                    return Promise.resolve(res);
                }
            }
        case 400:
            Toast("数据返回错误");
            return Promise.reject(new Error('错误请求'))
        case 401:
            Toast("数据返回错误");
            relogin()
            return Promise.reject(new Error('未授权，请重新登录'))
        default:
            Toast("数据返回错误");
            return Promise.reject(new Error('未知错误'))
    }
}, (error) => {
    Vue.$loading.hide()
    const response = error.response;
    const data = response && response.data
    const message = !data ? error.message : data.msg
   
    if (isDev) {
        console.info('%c返回数据::', 'color:#fff;font-size:14px;background-color:#333;');
        console.info(error);

    }
    if (data && (data.status == 404 || data.status == 403)) {
        // 后端校验失败
        console.error(error.config.url + ":" + error.message)
    }
    if (data && (data.status == 415)) {
        // 前端请求媒体类型错误
        console.error("请求体错误")
    }
    console.log(error.config.url)
   
    Toast("数据返回错误");

    return Promise.reject(new Error(message))
})
function relogin(){
    sessionStorage.removeItem('token')
    Vue.$router.replace('/login')
}
// 格式化序列
function serialize(obj) {
    if (obj.data != undefined) {
        let params = []
        Object.keys(obj.data).forEach(key => {
            if (nullCheck(obj.data[key])) params.push(`${key}=${obj.data[key]}`)
        })
        return `${obj.url}?${params.join('&')}`
    } else return obj.url
}

function nullCheck(obj) {
    // 校验是否为空对象、空数组、null、undefined
    if (obj === null || obj === undefined) return false
    if (Object.prototype.isPrototypeOf(obj) && Object.keys(obj).length === 0) return false
    else if (Array.prototype.isPrototypeOf(obj) && obj.length === 0) return false
    else return true
}

export default axiosA
