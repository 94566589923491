import Vue from 'vue'

// 引入需要的组件
import {
    Button, Swipe, SwipeItem, Toast, DropdownMenu, DropdownItem, Picker,
    CellGroup, Field,
    Popup, Icon, NavBar, Col, Row, Tab, Tabs, Tag, Image,
    Cell,ActionSheet,PullRefresh,List,Empty,Card,RadioGroup, Radio
} from "vant";

// 注册组件
Vue.use(Button);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Icon)
Vue.use(Popup)
Vue.use(Picker);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Toast);
Vue.use(NavBar)
Vue.use(CellGroup)
Vue.use(Field)
Vue.use(Cell)
Vue.use(Row)
Vue.use(Col)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Tag)
Vue.use(Popup)
Vue.use(Image)
Vue.use(ActionSheet)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Empty)
Vue.use(Card)
Vue.use(Radio);
Vue.use(RadioGroup);



 


// Vue.use(ActionBarIcon) 




