<template>
    <van-popup
        v-model="show"
        class="loading-pop"
    >
    <!-- {{ text || 'Loading' }} -->
        <van-loading vertical color="#0094ff"></van-loading>
    </van-popup>
</template>

<script>
import { Loading, Popup } from "vant";
export default {
    name: "loading",
    props: {
        show: Boolean,
        text: String
    },
    components: {
        [Loading.name]: Loading,
        [Popup.name]: Popup
    },
    mounted(){
        // this.loadingText = this.$t('base.loading') || 'Loading'

    },
    methods: {
    },
    data () {
        return {
            loadingText:''
        };
    }
};
</script>
<style lang="less" scoped>
.loading-pop{
    width:100px;
    border-radius:10px;
    line-height:100px;
    padding-top:20px;
    height:50px
}
</style>
