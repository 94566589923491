// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue"
import App from "./App"
import router from "@/router"
import loading from '@/libs/loading.js' // 引入loading
import "@/libs/rem.js"// 引入 移动端适配
import dateUtil from "@/libs/dateUtil.js";
import 'vant/lib/index.css';
import store from '@/store';
// import 'babel-polyfill'
// import promise from 'es6-promise'
// promise.polyfill()
import '@/utils/vant'
import {i18n, vantLocales} from './lang'
 
vantLocales(i18n.locale)
 

Vue.use(loading) // 全局使用loading

Vue.config.productionTip = false
Vue.prototype.$dateUtil = dateUtil

if (process.env.VUE_APP_IS_MOCK == 'true') {
    const { mockRequest } = require('@/mock/index')
    mockRequest()
   
}
import Vconsole from 'vconsole'
// if (process.env.VUE_APP_IS_CONSOLE == 'true') {
  // let vConsole = new Vconsole()
  // Vue.use(vConsole);  
// }
/* eslint-disable no-new */
new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app")
