import Mock from 'mockjs'
import api from './scan'
// 需要遍历的请求

// const mocks = [...api]

const mocks = []

// 设置200-2000毫秒延时请求数据
Mock.setup({
  timeout: '200-2000'
})

function param2Obj(url) {
  const search = decodeURIComponent(url.split('?')[1]).replace(/\+/g, ' ')
  if (!search) {
    return {}
  }
  const obj = {}
  const searchArr = search.split('&')
  searchArr.forEach(v => {
    const index = v.indexOf('=')
    if (index !== -1) {
      const name = v.substring(0, index)
      const val = v.substring(index + 1, v.length)
      obj[name] = val
    }
  })
  return obj
}
function XHR2ExpressReqWrap(respond,formData) {
  return function(options) {
      let result = null;
      if (respond instanceof Function) {
          const { body, type, url } = options;
          result = respond({
              method: type,
              body: formData == 'true' ?  body :JSON.parse(body),
              query: param2Obj(url),
          });
      } else {
          result = respond;
      }
      return Mock.mock(result);
  };
}

// 接口拦截
export function mockRequest() {
  let i
  console.log(i)

  for (i of mocks) {
      Mock.mock(new RegExp(i.url), i.type || 'get',XHR2ExpressReqWrap(i.response,i.formData)

    )
  }
}